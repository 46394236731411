/******* screen-medium.less *******/
:root {
  --locationsHeight: 72px;
}
@media (max-width: 1024px) {
  :root {
    --locationsHeight: 90px;
  }
}
@media (max-width: 767px) {
  :root {
    --locationsHeight: 100px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: 83.33333333%;
}
#head {
  margin-top: 35px;
}
.cb-layout2 #head {
  margin-top: 120px;
}
.cb-layout3 #head {
  width: calc(100% - 76px - 30px);
  margin-top: 0;
}
.backlink {
  top: 82px;
}
.mood .moodcontent {
  right: 8.33333333%;
  width: 41.66666667%;
}
h1 {
  font-size: 44px;
  font-size: 4.4rem;
}
.cb-layout2 h1 {
  font-size: 50px;
  font-size: 5rem;
}
.cb-layout3 h1 {
  font-size: 40px;
  font-size: 4rem;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 2.23880597%;
  margin-left: 2.23880597%;
}
.area .part {
  margin-right: 2.23880597%;
  margin-left: 2.23880597%;
  width: 95.52238806%;
}
.area .tiny {
  width: 45.52238806%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 4.47761194%;
  margin-left: 4.47761194%;
}
.area > .slim .part {
  width: 91.04477612%;
}
.area > .slim .tiny {
  width: 41.04477612%;
}
.area {
  width: 104.6875%;
  margin-left: -2.34375%;
  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.area:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.area.farwest {
  width: 76px;
  margin: 0;
}
.area .unit.seam .body {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.area .unit.seam.slim .part.tall {
  width: 68.95522388%;
}
.area .unit.seam.slim .part.tiny {
  width: 13.13432836%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */